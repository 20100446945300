
import { defineComponent } from 'vue'

export default defineComponent({
    props: ['active', 'list'],
    methods: {
        async onTab (index: number) {
            this.$emit('tabchange', index)
        }
    }
})
