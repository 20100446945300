
import { defineComponent } from 'vue'
import store from '@/store'
export default defineComponent({
    props: ['title'],
    data: function () {
        return {
            $store: store
        }
    },
    methods: {
        goNav (index: number) {
            this.$router.go(-(store.state.titles.length - index - 1))
        }
    }
})
